import logo from './logo.svg';
import './App.css';
import {Textarea, Label, Button} from "flowbite-react";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8080"

function App() {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-[1000px] mt-10 flex flex-col items-center">
        <h1 className="text-center text-3xl font-bold">Predict tweet engagement with AI (Build in Public)</h1>
        <p className="text-center mt-1 w-4/5">We scraped all 30k tweets from <a className="text-blue-600" href="https://twitter.com/buildinpublic">buildinpublic</a> since 4th August 2024. From this dataset, we used 4k random tweets to fine-tune GPT 3.5, by giving the amount of likes the tweet got along with its text. This model will categorize a tweet to predict how many likes it should get. Since it's impossible to predict with precision, the model can predict an expected range of count of likes.</p>
        <div className="w-full flex flex-col">
          <div className="mb-1 block w-full">
            <Label htmlFor="comment" value="Your tweet" />
          </div>
          <Textarea placeholder="..." color="light" id="tweet" rows="8"/>
          <Button color="dark" pill className="mt-3 w-fit pl-2 pr-2" onClick={() => {
            const result = document.getElementById("result")
            result.innerText = "Predicting..."
            fetch(apiUrl + `/evaluate-tweet?t="` + document.getElementById("tweet").value + `"`).then(response => {
              response.text().then(data => {
                let text = "Category: " + data
                
                if(text.includes("0")) {
                  text = text + " (Expecting 0-5 likes, inclusive)"
                } else if(text.includes("1")) {
                  text = text + " (Expecting 6-10 likes, inclusive)"
                } else if(text.includes("2")) {
                  text = text + " (Expecting 11-18 likes, inclusive)"
                } else if(text.includes("3")) {
                  text = text + " (Expecting 19+ likes)"
                }
                result.innerText = text
              })
            }).catch(error => {
              result.innerText = error
              alert("Error: " + error)
            })
          }}>Evaluate tweet</Button>
          <p id="result" className="mt-2"/>
        </div>
        
      </div>
    </div>
  );
}

export default App;
